import { useState } from "react";
import { Badge, Button, Dropdown, Menu, Popover, Layout } from "antd";
// import { DownOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { currencyActionCreators } from "../../redux/currency";
import { useSelector, useDispatch } from "react-redux";
import { userActionCreators } from "../../redux/user";
import "../../styles/header.css";
import formatter from "../../utils/formatter";
import { countries } from "../../assets/constants";
import AuthModal from "../AuthModal";

const { Header } = Layout;

const HeaderComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [buyerAuthModalVisible, setBuyerAuthModalVisible] = useState(false);
  const [buyerSignInAuthModalVisible, setBuyerSignInAuthModalVisible] =
    useState(false);

  const [showPopover, setShowPopover] = useState(false);
  const { info, isAuthenticated, type } = useSelector((state) => state.user);
  const { items: products, sum } = useSelector((state) => state.cart);
  const { data } = useSelector((state) => state.currency);
  const { code, indicator, rate } = data
    ? data
    : { code: "", currency: "", rate: "" };

  const { fetchCurrency } = bindActionCreators(
    currencyActionCreators,
    dispatch
  );

  const { logout } = bindActionCreators(userActionCreators, dispatch);

  const signOut = () => {
    logout();
    navigate("/");
  };

  const goHome = () => {
    if (isAuthenticated && info.role === "2") {
      if (info.email_verified === true) {
        if (info.has_created_store === false) {
          navigate("/create-store");
        }
        if (info.has_created_store === true) {
          navigate("/account");
        }
      }
      if (info.email_verified !== true) {
        navigate("/seller/email-verification");
      }
    } else {
      navigate("/");
    }
  };

  const handleVisibleChange = (newVisible) => {
    setShowPopover(newVisible);
  };

  const cartMenu = (
    <Menu>
      {products.length !== 0 ? (
        <div
          style={{
            padding: "16px",
            fontFamily: "DomaineSansText-Light",
            width: "400px",
            fontSize: "12px",
          }}
        >
          <div
            style={{
              textAlign: "center",
              borderBottom: " 1px solid #bfbfbf",
              paddingBottom: "4px",
            }}
          >
            <div>My Shopping Bag</div>
            <div>({products.length})</div>
          </div>
          <div>
            {products.map((item) => (
              <div
                style={{
                  display: "flex",
                  gap: "16px",
                  fontSize: "12px",
                  borderBottom: "1px solid #bfbfbf",
                  padding: "12px 0",
                }}
              >
                <div
                  className="cart-item-img"
                  style={{
                    backgroundImage: `url(${item.img1})`,
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    flex: 2,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "4px",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        textTransform: "uppercase",
                        fontFamily: "DomaineSansText-Regular",
                        fontWeight: "bold",
                      }}
                    >
                      {item?.store_info?.store_name}
                    </div>
                    <div
                      style={{
                        textTransform: "capitalize",
                        fontFamily: "DomaineSansText-Light",
                      }}
                    >
                      {item.name}
                    </div>
                    {item.selectedColor && item.selectedSize && (
                      <div
                        style={{
                          fontFamily: "DomaineSansText-Light",
                          display: "flex",
                          gap: "8px",
                        }}
                      >
                        <div
                          style={{
                            borderRight: "1px solid grey",
                            paddingRight: "8px",
                          }}
                        >
                          {item.selectedColor.name}
                        </div>
                        <div>{item.selectedSize}</div>
                      </div>
                    )}
                    <div>Qty: {item.quantityWanted}</div>
                  </div>
                  <div style={{ fontWeight: "bold" }}>
                    {formatter(item.price, rate, code, indicator)}
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "8px",
              }}
            >
              <div>Total</div>
              <div style={{ fontWeight: "bold" }}>
                {" "}
                {formatter(sum, rate, code, indicator)}
              </div>
            </div>
            <Button
              style={{
                color: "white",
                background: "black",
                width: "100%",
                fontSize: "12px",
                height: "40px",
                marginTop: "16px",
                fontFamily: "DomaineSansText-Light",
              }}
              onClick={() => navigate("/cart")}
            >
              Proceed To Bag
            </Button>
          </div>
        </div>
      ) : (
        <div className="header-cart-page-no-item-supercontainer">
          <div className="header-cart-page-no-item-container">
            <img
              className="header-cart-page-no-item-bag-icon"
              src="./images/icon-cart.svg"
              alt=""
            />
            <div className="header-cart-page-no-item-text">
              There are currently no items in your shopping bag
            </div>
            {(!isAuthenticated) &&
              <div className="header-cart-page-no-item-button">
              <Button
                className="btn-view-saved-items"
                onClick={() => setBuyerSignInAuthModalVisible(true)}
                type="primary"
              >
                Sign In
              </Button>
            </div>
            }
          </div>
        </div>
      )}
    </Menu>
  );

  const menu = (
    <Menu>
      {countries.map((currency, idx) => (
        <Menu.Item
          key={idx}
          onClick={() => {
            fetchCurrency(currency.code);
          }}
        >
          <img className="icon-cur" src={currency.icon} alt={currency.name} />
          <span className="txt-cur">{currency.name}</span>
        </Menu.Item>
      ))}
    </Menu>
  );
  const accountMenu = (
    <Menu className="account-menu" onClick={() => setShowPopover(false)}>
      <Menu.Item key="20">
        <h4
          className="btn-menu"
          type="primary"
          onClick={() => navigate("/account")}
        >
          My Dashboard
        </h4>
      </Menu.Item>
      <Menu.Item key="0">
        <h4
          className="btn-menu"
          type="primary"
          onClick={() => navigate("/orders")}
        >
          My Orders
        </h4>
      </Menu.Item>
      <Menu.Item key="1">
        <h4
          className="btn-menu"
          type="primary"
          onClick={() => navigate("/saved-items")}
        >
          My Saved Items
        </h4>
        hello there
      </Menu.Item>
      <Menu.Item key="2">
        <h4
          className="btn-menu"
          type="primary"
          onClick={() => navigate("/address")}
        >
          My Addresses
        </h4>
      </Menu.Item>
      <Menu.Item key="3">
        <h4
          className="btn-menu"
          type="primary"
          onClick={() => navigate("/settings")}
        >
          Account Details
        </h4>
      </Menu.Item>
      {/* <Menu.Item key="4">
        <h4
          className="btn-menu"
          type="primary"
          onClick={() => navigate("/losode-credits")}
        >
          Losode Credits
        </h4>
      </Menu.Item> */}
      <Menu.Item key="5">
        <h4
          className="btn-menu"
          type="primary"
          onClick={() => navigate("/email-preferences")}
        >
          Email Preference
        </h4>
      </Menu.Item>
      <span className="menu-line" />
      <Menu.Item key="6">
        <Button className="btn-sign-out" type="ghost" onClick={signOut}>
          Sign Out
        </Button>
      </Menu.Item>
    </Menu>
  );

  const guestMenu = (
    <Menu
      className="account-menu"
      onClick={() => setShowPopover(false)}
      style={{ margin: 22, textAlign: "center" }}
    >
      <Menu.Item key="26">
        <Button
          type="primary"
          onClick={signOut}
          style={{
            color: "#fff",
            height: 35,
            width: 250,
            fontFamily: "DomaineSansText-Regular",
            fontSize: 15,
          }}
        >
          Sign Out
        </Button>
      </Menu.Item>
      <h5
        style={{
          color: "#000",
          marginTop: 15,
          fontFamily: "DomaineSansText-Regular",
          fontWeight: 600,
          fontSize: 12,
        }}
      >
        Don’t have an account with us?{" "}
        <span
          style={{ color: "#6B1214", cursor: "pointer" }}
          onClick={() => {
            signOut();
            setBuyerAuthModalVisible(true);
          }}
        >
          Register
        </span>
      </h5>
    </Menu>
  );

  return (
    <Header className="homepage-header-layout" style={{ paddingRight: "0px" }}>
      <Menu className="menu-items-list" mode="horizontal" style={{ border: 0 }}>
        <Menu.Item key="1" style={{ border: 0, paddingLeft: 0 }}>
          <Dropdown overlay={menu} trigger={["click"]}>
            <a
              className="ant-dropdown-link"
              href="##"
              onClick={(e) => e.preventDefault()}
              style={{
                textDecoration: "none",
                fontSize: 18,
                fontFamily: "DomaineSansText-Light",
              }}
            >
              {/* <img
                className="icon-cur"
                src={countries.find((curr) => curr.code === code)?.icon}
                alt=""
              />{" "} */}
              {/* <DownOutlined style={{ fontSize: 10 }} /> */}
            </a>
          </Dropdown>
        </Menu.Item>
        <Menu.Item key="2" style={{ border: 0, marginTop: "-6px", paddingLeft: "2px"}}>
          <Link
            to="/women"
            className={type === "Women" ? "nav-item-active" : "nav-item"}
          >
            Women
          </Link>
        </Menu.Item>
        <Menu.Item key="3" style={{ border: 0, marginTop: "-6px", paddingLeft: "2px"}}>
          <Link
            to="/men"
            className={type === "Men" ? "nav-item-active" : "nav-item"}
          >
            Men
          </Link>
        </Menu.Item>
        <Menu.Item key="4" style={{ border: 0, marginTop: "-6px", paddingLeft: "2px"}}>
          <Link
            to="/kids"
            className={type === "Kids" ? "nav-item-active" : "nav-item"}
          >
            Kids
          </Link>
        </Menu.Item>
      </Menu>
      <div className="menu-logo-container">
        <img
          src="/images/losode-The-Logo-Tagline-Black2.png"
          alt="logo"
          className="logo"
          onClick={goHome}
        />
      </div>

      <Menu
        className="account-menu-items"
        mode="horizontal"
        style={{
          border: 0,
          width: 270,
          color: "#fff",
          display: "flex",
          justifyContent: "flex-end",
          marginLeft: "5px",
          backgroundColor: "transparent",
        }}
      >
        <>
        {info.fname && (
          <Menu.Item key="4" style={{ border: 0 }}>
            <div>
              <Popover
                className="account-popover"
                placement="topLeft"
                title={`Hello ${info.fname}`}
                content={accountMenu}
                trigger="click"
                arrow={{ pointAtCenter: true }}
                open={showPopover}
                onOpenChange={handleVisibleChange}
              >
                <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M9 2C7.34315 2 6 3.34315 6 5C6 6.65685 7.34315 8 9 8C10.6569 8 12 6.65685 12 5C12 3.34315 10.6569 2 9 2ZM4 5C4 2.23858 6.23858 0 9 0C11.7614 0 14 2.23858 14 5C14 7.76142 11.7614 10 9 10C6.23858 10 4 7.76142 4 5ZM1.46447 13.4645C2.40215 12.5268 3.67392 12 5 12H13C14.3261 12 15.5979 12.5268 16.5355 13.4645C17.4732 14.4021 18 15.6739 18 17V19C18 19.5523 17.5523 20 17 20C16.4477 20 16 19.5523 16 19V17C16 16.2044 15.6839 15.4413 15.1213 14.8787C14.5587 14.3161 13.7956 14 13 14H5C4.20435 14 3.44129 14.3161 2.87868 14.8787C2.31607 15.4413 2 16.2043 2 17V19C2 19.5523 1.55228 20 1 20C0.447715 20 0 19.5523 0 19V17C0 15.6739 0.526784 14.4021 1.46447 13.4645Z" fill="#202020"/>
</svg>

              </Popover>
            </div>
          </Menu.Item>
        )}
        {!info.fname && info.token && (
          <Menu.Item key="24" style={{ border: 0, padding: 0 }}>
            <div>
              <Popover
                className="account-popover"
                placement="topLeft"
                content={guestMenu}
                trigger="click"
                arrow={{ pointAtCenter: true }}
                open={showPopover}
                onOpenChange={handleVisibleChange}
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{fill:"transparent"}}
                  
                >
                  <path
                    d="M8.49992 8.49984C10.4559 8.49984 12.0416 6.91418 12.0416 4.95817C12.0416 3.00216 10.4559 1.4165 8.49992 1.4165C6.54391 1.4165 4.95825 3.00216 4.95825 4.95817C4.95825 6.91418 6.54391 8.49984 8.49992 8.49984Z" fill="#ffff"
                    stroke="white"
                    stroke-width="1.0"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.5847 15.5833C14.5847 12.8421 11.8576 10.625 8.50011 10.625C5.14261 10.625 2.41553 12.8421 2.41553 15.5833"fill="#ffff"
                    stroke="white"
                    stroke-width="1.0"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Popover>
            </div>
          </Menu.Item>
        )}
        {!info.token && (
          <div
          className="menu-icon"
          key="2"
          onClick={() => setBuyerSignInAuthModalVisible(true)}
        >
         <svg width="18" height="18" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg" style={{fill:"#fff"}}>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M9 2C7.34315 2 6 3.34315 6 5C6 6.65685 7.34315 8 9 8C10.6569 8 12 6.65685 12 5C12 3.34315 10.6569 2 9 2ZM4 5C4 2.23858 6.23858 0 9 0C11.7614 0 14 2.23858 14 5C14 7.76142 11.7614 10 9 10C6.23858 10 4 7.76142 4 5ZM1.46447 13.4645C2.40215 12.5268 3.67392 12 5 12H13C14.3261 12 15.5979 12.5268 16.5355 13.4645C17.4732 14.4021 18 15.6739 18 17V19C18 19.5523 17.5523 20 17 20C16.4477 20 16 19.5523 16 19V17C16 16.2044 15.6839 15.4413 15.1213 14.8787C14.5587 14.3161 13.7956 14 13 14H5C4.20435 14 3.44129 14.3161 2.87868 14.8787C2.31607 15.4413 2 16.2043 2 17V19C2 19.5523 1.55228 20 1 20C0.447715 20 0 19.5523 0 19V17C0 15.6739 0.526784 14.4021 1.46447 13.4645Z" fill="#fff"/>
          </svg>
        </div>
        )}
        {info.role === "2" && (
          <>
            <Menu.Item
              onClick={() => navigate("/account")}
              style={{ color: "black", textDecoration: "underline" }}
            >
              My Dashboard
            </Menu.Item>
            <span style={{ fontWeight: "bold" }}>|</span>
            <Menu.Item
              onClick={signOut}
              style={{ color: "#800000", textDecoration: "underline" }}
            >
              Sign Out
            </Menu.Item>
          </>
        )}
        </>
        {info.role !== "2" && (
          <>
            <div
              className="menu-icon"
              key="2"
              onClick={() => navigate("/saved-items")}
              style={{fill: "#fff", }}
            >
              <svg width="18" height="18" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg" style={{fill:"#fff"}}>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M14.46 0.495075C15.2488 0.168228 16.0943 0 16.9482 0C17.802 0 18.6475 0.168229 19.4363 0.495075C20.225 0.821861 20.9416 1.30081 21.5451 1.90455C22.1489 2.5081 22.6281 3.22501 22.9549 4.01368C23.2818 4.80249 23.45 5.64797 23.45 6.50183C23.45 7.35568 23.2818 8.20116 22.9549 8.98997C22.6281 9.77872 22.1491 10.4953 21.5453 11.0989C21.5452 11.099 21.5453 11.0989 21.5453 11.0989L12.7053 19.9389C12.3147 20.3295 11.6816 20.3295 11.2911 19.9389L2.45106 11.0989C1.23183 9.8797 0.546875 8.22607 0.546875 6.50183C0.546875 4.77758 1.23183 3.12395 2.45106 1.90472C3.67029 0.68549 5.32392 0.000534773 7.04817 0.000534773C8.77242 0.000534773 10.426 0.68549 11.6453 1.90472L11.9982 2.25761L12.3509 1.90488C12.3508 1.90494 12.3509 1.90483 12.3509 1.90488C12.9545 1.30106 13.6713 0.821892 14.46 0.495075ZM16.9482 2C16.357 2 15.7717 2.11647 15.2256 2.34274C14.6795 2.56902 14.1833 2.90068 13.7654 3.31877L12.7053 4.37893C12.3147 4.76946 11.6816 4.76946 11.2911 4.37893L10.2311 3.31893C9.3869 2.47478 8.24198 2.00053 7.04817 2.00053C5.85435 2.00053 4.70943 2.47478 3.86527 3.31893C3.02112 4.16309 2.54688 5.30801 2.54688 6.50183C2.54688 7.69564 3.02112 8.84056 3.86527 9.68472L11.9982 17.8176L20.1311 9.68472C20.5491 9.26683 20.881 8.77049 21.1072 8.22439C21.3335 7.67828 21.45 7.09295 21.45 6.50183C21.45 5.9107 21.3335 5.32537 21.1072 4.77926C20.881 4.23316 20.5493 3.73699 20.1312 3.3191C19.7133 2.90101 19.2168 2.56902 18.6707 2.34274C18.1246 2.11647 17.5393 2 16.9482 2Z"/>
              </svg>

            </div>
            <div className="menu-icon" key="3">
              <Dropdown overlay={cartMenu} placement="bottomRight" arrow trigger={["hover"]}>
                <Badge
                  count={products.length > 0 ? products.length : ""}
                  offset={[0, 20]}
                  showZero
                  size="small"
                  style={{
                    backgroundColor: "#000",
                    color: "#fff",
                    border: "0",
                  }}
                  onClick={() => navigate("/cart")}
                >
                  <svg width="18" height="18" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg" style={{fill:"transparent"}}>
                  <path d="M14.5984 7.39688V4.39688C14.5984 2.40865 12.9867 0.796875 10.9984 0.796875C9.01021 0.796875 7.39844 2.40865 7.39844 4.39687V7.39687M3.72571 21.1969H18.2712C19.5565 21.1969 20.5984 20.1743 20.5984 18.9129L19.1075 6.79684C19.1075 5.53542 18.0656 4.51283 16.7803 4.51283H4.92571C3.64039 4.51283 2.59844 5.53542 2.59844 6.79684L1.39844 18.9129C1.39844 20.1743 2.44039 21.1969 3.72571 21.1969Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </Badge>
              </Dropdown>
            </div>
          </>
        )}

       
      </Menu>

      <AuthModal
        authModalVisible={buyerAuthModalVisible}
        setAuthModalVisible={setBuyerAuthModalVisible}
        activePage="2"
        pageUserType="buyer"
      />
      <AuthModal
        authModalVisible={buyerSignInAuthModalVisible}
        setAuthModalVisible={setBuyerSignInAuthModalVisible}
        activePage="1"
        pageUserType="buyer"
      />

      <style jsx="true">{`
        .homepage-header-layout {
          align-items: center;
          background: #000000;
          display: flex;
          height: 75px;
          justify-content: space-between;
          font-family: "DomaineSansText-Regular";
        }
        .homepage-header-layout
          .ant-menu-horizontal:not(.ant-menu-dark)
          > .ant-menu-item:hover::after,
        .homepage-header-layout .ant-menu-horizontal > .ant-menu-item::after,
        .ant-menu-horizontal > .ant-menu-submenu::after {
          border-bottom: 0;
        }
        .user-account-dropdown {
        }
        .account-menu {
        }

        .account-menu .ant-menu-inline,
        .ant-menu-vertical,
        .ant-menu-vertical-left {
          border: 0px;
        }
        .menu-line {
          display: block;
          border: 0.3px solid #92959e;
          opacity: 0.3;
          margin-bottom: 24px;
        }

        .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
          background-color: transparent;
        }
        .ant-menu-item:active,
        .ant-menu-submenu-title:active {
          background: transparent;
        }
        .btn-sign-out {
          color: #677189;
          font: normal normal 16px/146.16% DomaineSansText-Light;
          width: 100%;
          height: 40px;
        }
        .btn-sign-out:hover {
          color: #fff;
          background: #000;
        }

        .btn-menu {
          color: #677189;
          font: normal normal 16px/146.16% DomaineSansText-Light;
          width: 100%;
        }
        .btn-menu:hover {
          color: #be002b !important;
          font-weight: 700;
        }
        .ant-menu-horizontal > .ant-menu-item::after,
        .ant-menu-horizontal > . ant-menu-submenu::after {
          display: none;
        }
        .icon-cur {
          height: 18px;
          width: 18px;
        }
        .txt-cur {
          font: normal normal 12px/22px DomaineSansText-Light;
          margin-left: 12px;
        }
        .nav-item {
          text-decoration: none;
          font-size: 15px;
          font-family: DomaineSansText-Light;
          padding: 0;
          margin: 0 !important;
          
        }
        .nav-item-active {
          text-decoration: none;
          font-size: 16px;
          font-family: DomaineSansText-Black;
          
        }
        .menu-icon {
          border: 0px;
          cursor: pointer;
          margin: 0px;
          padding: 0px 7px;
        }
        .menu-icon:nth-child(3) {
          padding-right: 0;
        }
        .menu-items-list {
          flex: 1;
          position: absolute;
          z-index: 201;
          // width: 35vw;
          padding-left: 2.9rem;
          background-color: transparent !important;;
          // margin-top: -10px;

        }
        .menu-items-list .ant-menu-item, .menu-items-list .ant-dropdown-link{
          color: white !important;
        }
        .account-menu-items {
          right: 0;
          position: absolute;
          z-index: 201;
          padding-right: 4rem;
        }
        .account-menu .ant-menu-item:hover {
          background-color: transparent !important;
        }
        .menu-logo-container {
          place-items: left;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          z-index: 200;
        }
        .menu-logo-container img {
          width: 138px;
        }
        .auth-span {
          color: #000;
          text-decoration: underline;
          font-size: 14px;
          font-family: DomaineSansText-Light;
        }
        .auth-span:hover {
          color: #be002b;
          text-decoration: none;
        }

        .ant-popover-placement-bottomRight .ant-popover-arrow {
          display: none;
        }
        .ant-popover-title {
          border-bottom: 1px solid #f0f0f0 !important;
          font-family: DomaineSansText-Regular;
          font-style: normal;
          font-weight: 300;
          font-size: 20px;
          padding: 15px 25px !important;
        }
        .ant-popover {
          width: 25vw;
          // left: 75% !important;
          padding-top: 10px;
        }

        .ant-popover-inner-content {
          width: auto;
        }

        .header-cart-page-no-item-supercontainer {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          padding: 16px 0;
          font-family: "DomaineSansText-Light";
        }
        .header-cart-page-no-item-container {
          background: white;
          padding: 24px 30px;
          font-size: 14px;
          text-align: center;
        }
        .header-cart-page-no-item-bag-icon {
          height: 24px;
        }
        .header-cart-page-no-item-title {
          font-size: 18px;
          margin-top: 14px;
          font-weight: 600;
        }
        .header-cart-page-no-item-text {
          font-size: 14px;
          margin-top: 10px;
          max-width: 220px;
          padding: 0 16px;
        }
        .header-cart-page-no-item-button {
          margin-top: 30px;
        }
        .btn-view-saved-items {
          color: #000;
          background: #fff;
          font: normal normal 14px/146.16% DomaineSansText-Light;
          width: 220px;
          height: 40px;
          box-shadow: none;
          border: 1px solid #000;
          border-radius: 4px;
        }
        .header-cart-page-no-item-shop-link {
          text-decoration: underline;
          cursor: pointer;
          margin-top: 10px;
        }

        .cart-item-img {
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          height: 138px;
          width: 96px;
        }
      `}</style>
    </Header>
  );
};

export default HeaderComponent;
